<template>
  <div>
    <v-select
      class="custom-select "
      :options="options"
      @input="inputSelected"
      :placeholder="placeholder"
      v-model="local"
      :disabled="disabled"
      :filterable="filterable"
      @search="onSearch"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"></span>
      </template>
    </v-select>
  </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
  name: 'Select2',
  props: ['options', 'forgettable', 'placeholder', 'selectedOption', 'disabled', 'filterable'],
  components: {
    vSelect
  },
  data () {
    return {
      local: ''
    }
  },
  watch: {
    selectedOption (newValue) {
      this.local = newValue
    }
  },
  methods: {
    inputSelected (e) {
      this.$emit('input', e)
      this.$emit('change', e)
      if (this.forgettable) this.local = ''
    },
    onSearch (searchTerm, loading) {
      loading(true)
      this.$emit('search', { searchTerm, loading })
    }
  }
}
</script>

<style lang="css" src="vue-select/dist/vue-select.css"></style>

<style>

.custom-select .vs__search {
  margin: 9.3px 0 9.3px !important;
}

.custom-select .vs__dropdown-toggle {
  padding: 0;
  border-color: #E4E6EF;
}

.custom-select .vs__actions {
  padding: 0 13px;
}

.custom-select .vs__open-indicator::before{
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px 12px;
    display: block;
    width: 16px;
    height: 12px
}
</style>
